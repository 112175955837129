import React from 'react'
import { bs } from '../shevy'
import MaxWidth from './MaxWidth'

export default function MainInner({ children }) {
  return (
    <MaxWidth>
      <div
        css={{
          padding: bs(2),
        }}
      >
        {children}
      </div>
    </MaxWidth>
  )
}
