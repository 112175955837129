import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

export default function Seo({
  description,
  lang = 'en',
  meta = [],
  keywords = [],
  title,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const {
          subTitle: metaSubTitle,
          title: metaTitle,
        } = data.site.siteMetadata

        const metaDescription =
          description || data.site.siteMetadata.description

        const ogImage = data.allImageSharp.edges[0].node

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${metaTitle} ${metaSubTitle}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: 'author',
                content: data.site.siteMetadata.author,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: 'og:image',
                content: `${data.site.siteMetadata.siteUrl}${ogImage.original.src}`,
              },
              {
                property: 'og:image:width',
                content: ogImage.original.width,
              },
              {
                property: 'og:image:height',
                content: ogImage.original.height,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

const detailsQuery = graphql`
  query DefaultSeoQuery {
    site {
      siteMetadata {
        title
        subTitle
        description
        author
        siteUrl
      }
    }
    allImageSharp(
      filter: { original: { src: { regex: "/courses-og-image/" } } }
    ) {
      edges {
        node {
          original {
            width
            height
            src
          }
        }
      }
    }
  }
`
